import Hero from "./Components/Hero";

const Homepage = () => {
	return (
		<div>
			<Hero />
		</div>
	);
};

export default Homepage;
