// import axios from "axios";

export const allProjects = [
	{
		name: "Developer Job Board",
		id: 3,
		to: "https://job-board-app-pink.vercel.app/",
		thumbnail: "",
		language: "",
		active: false,
		link: "https://job-board-app-pink.vercel.app/",
		description: "",
	},
	{
		name: "Get It Done (another todo-list)",
		id: 3,
		to: "",
		thumbnail: "",
		language: "",
		active: false,
		link: "https://github.com/skystanoyevitch/getitdone-todo-list",
		description: "",
	},
	{
		name: "Workout Logger",
		id: 3,
		to: "",
		thumbnail: "",
		language: "",
		active: false,
		link: "https://github.com/skystanoyevitch/workout-logger",
		description: "",
	},
];
